import {
  AdDetail,
  Recapture,
  Search,
  NotFound,
  HotDeal,
  SiteMap,
  LocationSiteMap,
  Financing,
} from './lazyImport'
import { STATES } from './utils/constants'

export default [
  {
    path: '/mapasite',
    exact: true,
    component: SiteMap,
  },
  {
    path: `/mapasite/:vehicleType(carros|motos)/:stateLocation(${STATES.map(
      state => state.uf.toLowerCase()
    ).join('|')})?/:page(pg[1-9][0-9]?)?`,
    exact: true,
    component: LocationSiteMap,
  },
  {
    path: '/ofertas/feiroes/:hotDeal/:buyCar(comprar)/:make/:model/:version/:doors/:years/:id/',
    exact: true,
    component: AdDetail,
  },
  {
    path: '/ofertas/feiroes/:hotDeal/:buyBike(comprar)/:make/:model/:version/:years/:id/',
    exact: true,
    component: AdDetail,
  },
  {
    path: '/ofertas/feiroes/:hotDeal/:vehicleType(carros|carros-novos|carros-usados|motos|motos-novas|motos-usadas)/:filters(.*)?',
    component: Search,
  },
  {
    path: '/ofertas/feiroes/:hotDeal/',
    component: Search,
  },
  {
    path: '/ofertas/:hotDeal(feiroes)/',
    exact: true,
    component: HotDeal,
  },
  {
    path: '/:urlBuy(comprar)/:urlGranted(busca-avancada-carros-motos)',
    exact: true,
    component: Search,
  },
  {
    path: '/:offer(ofertas)/',
    exact: true,
    component: HotDeal,
  },
  {
    path: '/:vehicleType(carros|carros-novos|carros-usados|motos|motos-novas|motos-usadas)/:filters(.*)?',
    component: Search,
  },
  {
    path: '/:buyBike(comprar)/:make/:model/:version/:years/:id/',
    exact: true,
    component: AdDetail,
  },
  {
    path: '/:buyCar(comprar)/:make/:model/:version/:doors/:years/:id/',
    exact: true,
    component: AdDetail,
  },
  {
    path: '/mensagem-enviada/',
    component: Recapture,
  },
  {
    path: '/financiamento-nao-concluido/',
    component: Recapture,
  },
  {
    path: '/:vehicleCategory/:vehicleType(carros|carros-novos|carros-usados)/:filters(.*)?',
    component: Search,
  },
  {
    path: '/:vehicleCategory(carros-eletricos|hatches|picapes|sedans|suvs|carros-economicos|carros-para-familia|carros-a-diesel|carros-de-luxo)/',
    exact: true,
    component: Search,
  },
  {
    path: '/comprar/financiamento/:adId',
    exact: true,
    component: Financing,
  },
  {
    component: NotFound,
  },
]
